// noinspection DuplicatedCode

const $ = require('jquery');

$(document).ready(function () {
    $('.js-flashy').each( function(){
        let longueurTexte= $(this).find('.js-flashy-message').html().length
        let duration=900;
        if (longueurTexte>50){
            duration=3000;
        }else if(longueurTexte>25){
            duration=1800;
        }
        $(this)
            .removeClass('d-none').hide()
            .fadeIn(200)
            .delay(duration)
            .fadeOut(500, function() {
                $(this).remove()
            } )
    })

})

