
require('bootstrap');


// any CSS you import will output into a single css file (app.css in this case)
import './styles/global.scss';
import '/node_modules/aos/dist/aos.css';


const $ = require('jquery');
import {manageMenu,manageTooltip} from "./outils/OutilsDivers";
import './outils/aos'
import './outils/flashy_messages'



$(document).ready(function () {

    manageMenu()
    manageTooltip()

    //spinner sur submit
    $("#submit-form,#submit").on('click', function () {
        $('#spinner-save').show()
    })

    // $(".js-window").on('click',function(){
    //     let url=$(this).data('url');
    //     window.open(url, "", "width=800,height=600");
    // });


})
